import React, { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { FoodPrefs } from 'queries/user/user-get-prefs';
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import CheckComponent from '/components/form/Check';
import RadioComponent from '/components/form/Radio';
import TextComponent from '/components/form/Text';
import DateOfArrivalInput from 'containers/forms/DateOfArrivalInput';

interface Props {
  control: Control<FieldValues, any>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  options: FoodPrefs;
}

const PageThree: React.FC<Props> = ({ control, watch, setValue, options }) => {
  const otherInputWatch: string = watch('otherInput');

  useEffect(() => {
    if (!otherInputWatch || otherInputWatch.length === 0) return;
    setValue('other', true);
  }, [otherInputWatch, setValue]);

  return (
    <>
      <div style={{ marginTop: '25px' }}>
        <DateOfArrivalInput control={control} />
      </div>

      <div style={{ marginTop: '25px' }}>
        <Typography variant="body1">Drink Preferences</Typography>
        <RadioComponent
          name="drink"
          control={control}
          defaultValue=""
          options={options.drinkingPrefs.map((drink) => ({ value: drink, label: drink }))}
        />
      </div>

      <div style={{ marginTop: '25px' }}>
        <Typography variant="body1">Food Preferences</Typography>
        {options.foodPrefs.map((food) => (
          <CheckComponent
            key={`food-preferences-option-${food}`}
            name={food}
            control={control}
            label={<Typography variant="body2">{food}</Typography>}
            noMargin
          />
        ))}
      </div>

      <div style={{ marginTop: '25px' }}>
        <Typography variant="body1">Allergies/Intolerances (Optional)</Typography>
        {options.allergies.map((food) => (
          <CheckComponent
            key={`allergies-preferences-option-${food}`}
            name={food}
            control={control}
            label={<Typography variant="body2">{food}</Typography>}
            noMargin
          />
        ))}
        <CheckComponent
          name="other"
          control={control}
          label={
            <TextComponent name="otherInput" control={control} label="" placeholder="Other" variant="standard" small />
          }
          noMargin
        />
      </div>
    </>
  );
};

export default PageThree;
