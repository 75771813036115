import * as Backend from 'queries/BackendRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export interface SetUserPrefsForm {
  projectId: string;
  arrivalDate: string;
  drinkingPref: string;
  foodPrefs: string[];
  other: string;
}

const userSetPrefs = async (form: SetUserPrefsForm): Promise<void> => {
  const proms: Promise<any>[] = [];
  proms.push(
    Backend.request('post', `/user/${form.projectId}/food/1`, undefined, {
      foodPref: [form.drinkingPref],
    })
  );
  proms.push(
    Backend.request('post', `/user/${form.projectId}/food/2`, undefined, {
      foodPref: form.foodPrefs,
    })
  );
  proms.push(
    Backend.request('post', `/user/${form.projectId}/food/3`, undefined, {
      foodPref: form.other.length > 0 ? [form.other] : [],
    })
  );
  proms.push(
    Backend.request('post', `/user/assign/${form.projectId}`, undefined, {
      arrivalDate: form.arrivalDate,
      isTosAgreed: true,
    })
  );
  await Promise.all(proms);
};

const useUserSetPrefs = ({ projectId }: { projectId: string | number }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (form: SetUserPrefsForm) => {
      await userSetPrefs(form);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['userInProject', projectId, true] });
      navigate(`/${projectId}/events`);
    },
  });
};

export default useUserSetPrefs;
