import { useQuery } from '@tanstack/react-query';
import useOpenFeedback from 'hooks/useOpenFeedback';
import useProjectId from 'hooks/useProjectId';
import { userGetPrefs } from 'queries/user/user-get-prefs';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import useUserSetPrefs from 'queries/user/useUserSetPrefs';

const useProjectInfoForm = () => {
  const projectId = useProjectId();
  const feedback = useOpenFeedback();
  const setPrefs = useUserSetPrefs({ projectId });
  const prefs = useQuery(userGetPrefs({ projectId }));
  const [currentPage, setCurrentPage] = useState(0);
  const { handleSubmit, control, setValue, getValues, watch } = useForm({ mode: 'all' });

  const handleBack = () => {
    setCurrentPage(currentPage - 1);
  };

  const onSubmit = useCallback(
    (data: { [x: string]: any }) => {
      if (!prefs.data) return;

      // next page
      if (currentPage < 2) {
        setCurrentPage(currentPage + 1);
        return;
      }

      let error = false;
      // date validation
      const { arrivalDate } = data;
      const dateObject = new Date(arrivalDate);

      if (arrivalDate === null || !(!Number.isNaN(dateObject.getTime()) && dateObject !== null)) {
        feedback.openError('Please select a valid date');
        error = true;
      }

      // drink Pref
      const drinkPref: string = data.drink;
      if (!drinkPref) {
        feedback.openError('Please select a drink preference');
        error = true;
      }

      // food pref
      const foodPref: string[] = [];
      const foodPrefInput: boolean[] = getValues(prefs.data.fullPrefs);
      foodPrefInput.forEach((pref, index) => {
        if (pref === true) foodPref.push(prefs.data.fullPrefs[index]);
      });
      if (!foodPref.length || !foodPref.some((fpref) => prefs.data.foodPrefs.includes(fpref))) {
        feedback.openError('Please select a food preference');
        error = true;
      }

      if (error) return;

      setPrefs.mutate({
        projectId,
        drinkingPref: drinkPref,
        foodPrefs: foodPref,
        other: data.other && data.otherInput ? data.otherInput : '',
        arrivalDate: data.arrivalDate as string,
      });
    },
    [prefs.data, currentPage]
  );

  return {
    prefs,
    currentPage,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    onSubmit,
    handleBack,
  } as const;
};

export default useProjectInfoForm;
