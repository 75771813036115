import * as Backend from 'queries/BackendRequest';

interface Prefs {
  projectId: string;
}

export interface FoodPrefs {
  drinkingPrefs: string[];
  fullPrefs: string[];
  foodPrefs: string[];
  allergies: string[];
}

export const getPrefs = async ({ projectId }: Prefs): Promise<FoodPrefs> => {
  const proms = [];
  proms.push(Backend.request<string[]>('get', `/user/${projectId}/food-prefs/1`));
  proms.push(Backend.request<string[]>('get', `/user/${projectId}/food-prefs/2`));

  const result = await Promise.all(proms);

  const foodPrefs = result[1].data.data;
  foodPrefs.unshift(foodPrefs.pop() || '');

  return {
    drinkingPrefs: result[0].data.data,
    fullPrefs: foodPrefs,
    foodPrefs: foodPrefs.slice(0, 6),
    allergies: foodPrefs.slice(6),
  };
};

export const userGetPrefs = ({ projectId }: Prefs) => ({
  queryKey: ['getPrefs', projectId],
  queryFn: () => getPrefs({ projectId }),
  enabled: !!projectId,
});
