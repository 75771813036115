import React from 'react';
import { Typography } from '@mui/material';
import { useProjectTextContext } from 'context/ProjectTextContext';

const PageOne: React.FC = () => {
  const { PROJECT_NAME: name } = useProjectTextContext();
  return (
    <>
      <Typography variant="h6" style={{ marginTop: '16px', marginBottom: '16px' }}>
        Welcome!
      </Typography>
      <p>Please complete the next steps to join {name} and to view all the events.</p>
    </>
  );
};

export default PageOne;
