import React from 'react';

import { Control } from 'react-hook-form';
import { useProjectTextContext } from 'context/ProjectTextContext';
import Emojify from 'components/emojify/Emojify';
import ReactMarkdown from 'react-markdown';
import 'property-information';
import CheckComponent from 'components/form/Check';

interface Props {
  control: Control<any, any>;
}

const PageTwo: React.FC<Props> = ({ control }) => {
  const { PROJECT_RULES: text } = useProjectTextContext();
  return (
    <>
      <Emojify>
        <ReactMarkdown>{text ?? ''}</ReactMarkdown>
      </Emojify>

      <CheckComponent
        name="isTosAgreed"
        control={control}
        rules={{ validate: { isTrue: (v: boolean) => v === true || 'You must agree' } }}
        noMargin
        label="I agree to the terms laid out above"
      />
    </>
  );
};

export default PageTwo;
