import React, { useEffect, useState } from 'react';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import { CircularProgress } from '@mui/material';
import FormContainer from 'components/formContainer/FormContainer';
import FormLogo from 'components/formLogo/FormLogo';
import StepperComponent from 'components/form/Stepper';
import useProjectInfoForm from './hooks/useProjectInfoForm';
import PageOne from './pageOne/PageOne';
import PageTwo from './pageTwo/PageTwo';
import PageThree from './pageThree/PageThree';

const WriteProjectInfo: React.FC = () => {
  const { prefs, currentPage, handleSubmit, handleBack, control, setValue, watch, onSubmit, getValues } =
    useProjectInfoForm();
  const [nextDisabled, setNextDisabled] = useState(false);
  const watchTos = watch('isTosAgreed', false);

  useEffect(() => {
    if (currentPage === 1) {
      const tosAgreed = getValues('isTosAgreed');
      if (tosAgreed === undefined || tosAgreed === false) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
  }, [watchTos, currentPage]);

  const whichPage = () => {
    if (prefs.isLoading || !prefs.data) return <CircularProgress color="secondary" />;

    switch (currentPage) {
      case 0:
        return <PageOne />;
      case 1:
        return <PageTwo control={control} />;
      case 2:
        return <PageThree control={control} watch={watch} setValue={setValue} options={prefs.data} />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          {whichPage()}
          <StepperComponent
            active={currentPage}
            stepAmount={3}
            nextLoading={false}
            handleNext={handleSubmit(onSubmit)}
            handleBack={handleBack}
            nextDisabled={nextDisabled}
          />
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default WriteProjectInfo;
